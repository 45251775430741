import React from "react"
import { Link } from "gatsby"

import Layout from "../components/Layout/Layout"
// import Image from "../components/image"
import SEO from "../components/seo"
import HomePage from "../components/HomePage/HomePage"

import "../scss/custom.scss"

const IndexPage = () => (
    <div style={{overflowX:'hidden'}}>
      <Layout>
        <SEO title="首頁" />
        <HomePage />
      </Layout>
    </div>
)

export default IndexPage
