import React from "react"
import {
  Col,
  Card,
  CardText, CardBody,
  CardTitle, CardSubtitle,
  Button

} from 'reactstrap';

const MembershipCard = ({ sku, stripePromise }) => {
  const redirectToCheckout = async (event, sku, quantity = 1) => {
    event.preventDefault()
    const stripe = await stripePromise
    const { error } = await stripe.redirectToCheckout({
      items: [{ sku, quantity }],
      successUrl: `${window.location.origin}/success`,
      cancelUrl: `${window.location.origin}/`,
    })
    if (error) {
      console.warn("Error:", error)
    }
  }
  return (
    <Col className="text-center mb-4">
      <Card className="card-style">
        <CardBody>
          <CardTitle className="cardtitle">{sku.title}</CardTitle>
          {/*<CardSubtitle className="cardsubtitle">{sku.value}</CardSubtitle>*/}
          <CardText className="cardtext">
            {sku.text}
          </CardText>
          <div className="cardlink">
          (
            <a href="https://drive.google.com/file/d/1Csdb78AsnBEWmBvRxvtY_aSsm6OMy8FG/view?usp=sharing">在附件內指定日本醫院體檢中選擇</a>
          )
          </div>

          <Button
            className="apply-button"
            onClick={event =>redirectToCheckout(event, sku.stripeProdSKU)}
          >
            立即申請
          </Button>
        </CardBody>
      </Card>
    </Col>
  )
}
export default MembershipCard
