import React from "react"
import {
  Col,
  Container,
  Row,
  Button,
  Card,
  CardText, CardBody,
  CardTitle, CardSubtitle,

} from 'reactstrap';

import GetMembershipJson from "../GetMembershipJson/GetMembershipJson"
import image1 from "../../images/banner/MRI-image.jpeg"

import {
  FaHeadset, FaRegCalendarAlt, FaRegCheckCircle, FaBookMedical, FaUserNurse, FaRegEnvelope,
  FaWpforms, FaRegCreditCard
} from "react-icons/fa";
import { MdTranslate, } from "react-icons/md"
import { GoChecklist, GoPerson} from "react-icons/go"


const HomePage = class extends React.Component {


  constructor(props) {
    super(props);
    this.state = {

    }
  }

  render(){
    return (
      <>
        <div className="homepage-container">

          <div className="hero-image">
            <div className="hero-text">
              <h1 className="title">JBC日本體檢 </h1>
              <p className="subtitle">日本先進的醫療技術</p>
            </div>
          </div>
          <Container className="">
            <Row className="about-section" id="about">
              <Col xs="12" md="6" className="text-left about-left-section">
                <div>
                  <h4 className="seciton-title">關於JBC</h4>
                  <p>
                    日本先進的醫療技術(Advanced Medical Technology) 不但提高疑難病症的治癒率、而且為患者在延長生命及疾病預防、早期發現疾病方面帶來福音。
                  </p>
                  <p>
                    微創治療、微創撿查是身體不受損傷或僅受較輕損傷的診斷撿查、大大減輕患者接受疾病治療時的痛䓀、 提高患者生活品質、使患者病癒後可盡快恢復日常生活和工作。
                    在日本高科技電腦X射線斷層顯像(CT)、磁力共振(MRI)、電子發射型電腦斷層顯像(PET)的高度普及、每百萬人口人均擁有醫療設備台數處於世界最高的水平。
                  </p>
                  <p>
                    而且、日本在利用PET藥物傳輸系統治療癌症、內窺鏡微創治療、癌症重離子線放射線治療、再生醫療等領、均擁有世界先進技術。
                  </p>

                </div>
              </Col>
              <Col xs="12" md="6" className=" about-right-section">
                <img src={image1} alt="" className="right-image"/>
              </Col>
            </Row>

            <div className="membership-section" id="membership">
              <div className="seciton-title text-center">會籍內容</div>
              <Row className="row-cols-2 row-cols-md-3">
                <GetMembershipJson />
              </Row>
            </div>

            <div className="services-section" id="services">
              <div className="seciton-title text-center">服務內容</div>
              <Row className="service-row" >
                <Col xs="12" md="3">
                  <div className="service-container text-center">
                    <FaHeadset size="1.8rem" color="#869dd9" className="my-3"/>
                    <div className="service-text">24小時醫療資詢服務</div>
                  </div>
                </Col>
                <Col xs="12" md="3">
                  <div className="service-container text-center">
                    <FaRegCalendarAlt size="1.8rem" color="#869dd9" className="my-3"/>
                    <div className="service-text">24小時預約服務</div>
                  </div>
                </Col>
                <Col xs="12" md="3">
                  <div className="service-container text-center">
                    <FaRegCheckCircle size="1.8rem" color="#869dd9" className="my-3"/>
                    <div className="service-text">基本檢查(*): <br/>包含診察、問診、身體測量、尿、便檢查、血液檢查、眼科檢查、聽力檢查</div>
                  </div>
                </Col>
                <Col xs="12" md="3">
                  <div className="service-container text-center">
                    <FaBookMedical size="1.8rem" color="#869dd9" className="my-3"/>
                    <div className="service-text">各醫療機構的健康體檢商品額外8折優惠</div>
                  </div>
                </Col>
              </Row>
              <Row className="service-row" id="">
                <Col xs="12" md="3">
                  <div className="service-container text-center">
                    <MdTranslate size="1.8rem" color="#869dd9" className="my-3"/>
                    <div className="service-text">前日或當天由醫療翻譯用中文進行事先說明</div>
                  </div>
                </Col>
                <Col xs="12" md="3">
                  <div className="service-container text-center">
                    <FaUserNurse size="1.8rem" color="#869dd9" className="my-3"/>
                    <div className="service-text">體檢當天的檢查過程由醫療翻譯用中文進行說明</div>
                  </div>
                </Col>
                <Col xs="12" md="3">
                  <div className="service-container text-center">
                    <FaRegEnvelope size="1.8rem" color="#869dd9" className="my-3"/>
                    <div className="service-text">郵寄體檢前事先說明資料</div>
                  </div>
                </Col>
                <Col xs="12" md="3">
                  <div className="service-container text-center">
                    <GoChecklist size="1.8rem" color="#869dd9" className="my-3"/>
                    <div className="service-text">翻譯及郵寄檢查結果報告書</div>
                  </div>
                </Col>
              </Row>
              <Row className="">
                <Col xs="8" >
                  <div className="service-fineprint text-left">
                    *如有任何爭議或修改，Cubi-X Advisory Group Limited並不作另行通知及保留最終決定權。
                  </div>
                </Col>
                <Col xs="8" >
                  <div className="service-fineprint text-left">
                    *「JBC會藉」產品及服務，包括但不限於系統對接支持、產品運營、線上線下聯合推廣以及訂單響應、訂單退改、售後服務、服務質量考核等一系列服務品質，一概與Jentic Ltd無關，Jentic Ltd只提供銷售平台，請參考「免責聲明」。
                  </div>
                </Col>
              </Row>
            </div>

            <div className="application-section" id="application">
              <div className="seciton-title text-center">申請方法</div>
              <Row>
                <Col xs="12" md="4">
                  <div className="application-container text-center">
                    <FaWpforms size="1.8rem" color="#869dd9" className="my-3"/>
                    <div className="application-text">選定會籍後<br/>按「立即申請」填上個人資料</div>
                  </div>
                </Col>
                <Col xs="12" md="4">
                  <div className="application-container text-center">
                    <FaRegCreditCard size="1.8rem" color="#869dd9" className="my-3"/>
                    <div className="application-text">付款成功後將收到<br/>確認電郵通知</div>
                  </div>
                </Col>
                <Col xs="12" md="4">
                  <div className="application-container text-center">
                    <GoPerson size="1.8rem" color="#869dd9" className="my-3"/>
                    <div className="application-text">三個工作天內會收到<br/>JBC會員號碼及有效日期</div>
                  </div>
                </Col>
              </Row>
            </div>

          </Container>
        </div>

      </>
    )
  }

}

export default HomePage
