import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import MembershipCard from '../HomePage/MembershipCard'
import { loadStripe } from "@stripe/stripe-js"

const stripePromise = loadStripe(process.env.GATSBY_STRIPE_PUBKEY)

const GetMembershipJson = (props) => {
  const memberships = useStaticQuery(graphql`
    query allpackagesQuery {
      allMembershipsJson {
        edges {
          node {
            title
            value
            text
            stripeProdSKU
          }
        }
      }
    }
  `)

  return (
    <>
    {
      memberships.allMembershipsJson.edges.map(({ node, index }) => {
        const membership = node;
        console.log(node);

        return (
          <MembershipCard key={membership.id} sku={membership} stripePromise={stripePromise} />
        )
      })
    }
    </>
  )
}

export default GetMembershipJson
